import React from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { Colors, Hero } from "../../components/shared/Hero";
import { Button } from "../../components/shared/Button";
import MainImage from "../../images/baseData/baseData-image.svg";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { routes } from "../../routes/routes";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Seo } from "../../components/shared/Seo";

function BaseData() {
  const { t } = useTranslation();
  const bgColor = Colors.transparent;
  const { navigate } = useI18next();

  return (
    <Layout>
      <Seo
        title={t("baseData.title")}
        description={t("baseData.mainText1")}
        image={MainImage}
      />
      <Hero pic={{ wrap: Image, src: MainImage }} backgroundColor={bgColor}>
        <BreadCrumbs
          root={{ link: routes.tbizedi.edi, title: "tbizedi" }}
          current={"tbizediSubs.baseData"}
        />
        <Heading color={bgColor}>{t("baseData.title")}</Heading>
        <HeroDesc color={bgColor}>{t("baseData.mainText1")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("baseData.mainText2")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("baseData.mainText3")}</HeroDesc>
        <ButtonsWrap>
          <Button onClick={() => navigate(routes.contact.contactForm)}>
            {t("baseData.button")}
          </Button>
        </ButtonsWrap>
      </Hero>
    </Layout>
  );
}

export default BaseData;

const Image = styled("img", {
  width: "90%",
  maxWidth: "440px",
  objectFit: "contain",
});

const Heading = styled("h1", {
  color: "$white",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "30px 0 0",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },

  "@md": {
    margin: "0",
    fontSize: "48px",
    lineHeight: "56px",
    color: "$heading",
    maxWidth: "620px",
  },
});

const HeroDesc = styled("p", {
  color: "$white",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "12px 0 0",
  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },
  "&:last-of-type": {
    margin: "12px 0 32px",
  },
  "@md": {
    color: "$text",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginBottom: 80,
  "@md": {
    flexDirection: "row",
  },
});
